'use strict';
import * as utils from './utils.mjs';
import * as langutils from './langutils.mjs';
import * as messages from '../messages/common.json';
const lodash = window._;
const idleScreenLockMin = 15;
const idleLogOutMin = 30;
let idleTimerInterval = null;

function resetcount(){
  utils.appdb.getItem('tmpvars', function(err1, tmpvars){
    if(err1){
      console.error(err1);
      return;
    }

    if(!tmpvars){
      tmpvars = {
        idleTime: 0
      };
      utils.appdb.setItem('tmpvars', tmpvars, function(err2){
        if(err2){
          console.error(err2);
          return;
        }
      })
      return;
    }

    tmpvars.idleTime = 0;
    utils.appdb.setItem('tmpvars', tmpvars, function(err){
      if(err){
        console.error(err);
        return;
      }
    });
  })
}
const debouncefunc = lodash.debounce(resetcount, 500);

const data = {
  geodoc: {},
  session: {},
  appcfg: {},
  uis: {
    loaded: false,
    error: null,
    show_logout_modal: false,
    navgap: 20,
    alert: false,
    alertdata: {
      message: null,
      uri: null
    },
    messages: {},
    screenlock: null,
    screenunlocking: false,
    password: '',
    showlocation: false,
    show_modal_message: false,
    modalmessage: null,
    modalmessage_title: null
  },
  vs: {}
};

////////////////////////////////

const vuecommon = module.exports = {
  data: function() {
    return data
  },

  created: function created(){
    this.loadStateFromLocalDB();
  },

  mounted: function mounted() {
    this.startIdleMonitors(this);
    this.idleTimer(); //run it once now
  },

  methods: {
    logout: function logout(){
      this.session = {};
      utils.logout();
    },

    gethref: function gethref(location){
      return utils.gethref(location);
    },

    clearError: function clearError(){
      this.uis.error = null;
    },

    showLogoutModal: function showLogoutModal(){
      this.uis.show_logout_modal = true;
    },

    clearLogoutModal: function clearLogoutModal(){
      this.uis.show_logout_modal = false;
    },

    t: function t(key, defaultStr, beautify){
      return langutils.getString(this.uis.messages, key, defaultStr, beautify);
    },

    startIdleMonitors: function startIdleMonitors(){
      const me = this;
      if(!idleTimerInterval){ //make sure we dont start it twice somehow
        idleTimerInterval = setInterval(me.idleTimer, 1000 * 60);
      }
      window.addEventListener("mousemove", debouncefunc);
      window.addEventListener("scroll", debouncefunc);
      window.addEventListener("keydown", debouncefunc);
      window.addEventListener("resize", debouncefunc);
    },

    idleTimer: function idleTimer(){
      const me = this;
      utils.appdb.getItem('tmpvars', function(err, tmpvars){
        if(err){
          console.error(err);
          return;
        }

        if(!tmpvars){
          tmpvars = {};
        }
        let idleTime = lodash.get(tmpvars, 'idleTime', 0);
        if(idleTime == null){
          idleTime = 0;
        }
        idleTime++;
        console.info(`Idle for ${idleTime} minutes.`);
        tmpvars.idleTime = idleTime;

        utils.appdb.setItem('tmpvars', tmpvars, function(err){
          if(err){
            console.error(err);
          }

          if(idleTime > idleScreenLockMin){
            window.removeEventListener("mousemove", debouncefunc);
            window.removeEventListener("scroll", debouncefunc);
            window.removeEventListener("keydown", debouncefunc);
            window.removeEventListener("resize", debouncefunc);
            me.uis.screenlock = {
              message: 'Are you still there?'
            }
            setTimeout(function(){
              me.$refs.screenlockpw.focus();
            }, 500);
          }

          if(idleTime > idleLogOutMin){
            me.logout();
          }
        });
      });
    },

    handleError: async function handleError(e){
      console.error(`Error: `, e);
      if(e.response){
        let body = {}
        try{
          body = await e.response.json();
        } catch (eee) {
          console.error('Error response has no body')
        }
        console.info(`Route result: ${e.response.status} ${JSON.stringify(e.response.headers)} ${JSON.stringify(e.response.statusText)} ${JSON.stringify(body)}`);
        if(e.response.status == 401){
          console.info('Rerouting for authentication...');
          utils.routenoauth();
          return;
        }
        this.uis.loaded = true;
        this.uis.error= {
          message: `${e.response.status} ${body.message}`
        }
      } else {
        this.uis.loaded = true;
        this.uis.error= {
          message: e.message
        }
      }
    },

    showModalMessage: function showModalMessage(message, title){
      this.uis.modalmessage = message;
      this.uis.modalmessage_title = title;
      this.uis.show_modal_message = true;
    },

    dismissModalMessage: function dismissModalMessage(){
      this.uis.show_modal_message = false;
      this.uis.modalmessage_title = null;
      this.uis.modalmessage = null;
    },

    clearScreenLock: async function clearScreenLock(){
      const me = this;
      me.uis.screenunlocking = true;
      const pw = me.uis.password;
      me.uis.password = '';
      try{
        const fetchopts = await utils.getfetchopts({
          timeout: 30000,
          hooks: {
            beforeRequest: [
              request => {
                console.info(`Request: ${request.method} ${request.url}`);
              }
            ]
          },
          json: {
            username: this.session.username,
            password: pw
          }
        });
        delete fetchopts.headers;
        const result = await utils.bmapi.post(`access/unlock`, fetchopts).json();
        console.info(`Result: ${JSON.stringify(result, null, 2)}`)
        me.uis.screenlock = null;
        resetcount();
        window.addEventListener("mousemove", debouncefunc);
        window.addEventListener("scroll", debouncefunc);
        window.addEventListener("keydown", debouncefunc);
        window.addEventListener("resize", debouncefunc);
      } catch (e){
        if(e.response){
          let body = {}
          try{
            body = await e.response.json();
          } catch (eee) {
            console.error('Error response has no body')
          }
          console.info(`Route result: ${e.response.status} ${JSON.stringify(e.response.headers)} ${JSON.stringify(e.response.statusText)} ${JSON.stringify(body)}`);
          me.uis.screenlock.submessage = `${e.response.status} ${body.message}`;
        } else {
          me.uis.screenlock.submessage = e.message;
        }
      } finally {
        me.uis.screenunlocking = false;
      }
    },

    loadStateFromLocalDB: async function loadStateFromLocalDB(){
      try{
        console.info('loading session and config to state...')
        const session = await utils.store('session');
        this.session = session;
        const geodoc = await utils.store('geodoc');
        this.geodoc = geodoc;
        const appcfg = await utils.store('appcfg');
        this.appcfg = appcfg;

        if(lodash.get(appcfg, 'appconf.core/default.alert.message')){
          if(this.uis){
            this.uis.alert = true;
            this.uis.alertdata = lodash.get(appcfg, 'appconf.core/default.alert');
          }
        }

      } catch (e){
        console.error(e);
        data.error = {
          message: e.message
        }
      }
    },

    mergeCommonMessages: function mergeCommonMessages(locale, msgs){
      let lm = messages[`${locale.language}-${locale.region}`];
      if(!lm){
        lm = messages['en-US'];
      }
      lodash.assign(msgs, lm);
      return msgs;
    },

    toggleLocation: function toggleLocation(){
      this.uis.showlocation = !this.uis.showlocation
    }

  }
}


//////////////////////

let hidden = "webkitHidden";
let visibilityChange = "webkitvisibilitychange";
if(typeof document.hidden !== "undefined") { // Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
}
document.addEventListener(visibilityChange, function(){
  if(!document[hidden]){
    vuecommon.methods.loadStateFromLocalDB();
  }
}, false);
