/* global ky, localforage */
'use strict';
const lodash = window._;
const luxondt = window.luxon.DateTime;
let _apistage = '/lts'
let _token = null;
const _appdb = localforage.createInstance({
  name: "BusinessMind",
  storeName: 'bmdata',
  description: 'BusinessMind IndexDB Database'
});

const _queryroutes = ["metadata", "metrics", "sets", "tabular"];
const _integrationroutes = ["birdeye", "xero", "zillion", "podium", "shopify"];

///////////////////

function checkDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

function watchDarkMode() {
  if (!window.matchMedia) return;

  window.matchMedia('(prefers-color-scheme: dark)').addListener(addDarkModeSelector);
}

function addDarkModeSelector() {
  if (checkDarkMode()) {
    console.info('Using darkmode...');
    document.documentElement.classList.add('mode-dark');
  } else {
    document.documentElement.classList.remove('mode-dark');
  }
}

async function _dbdump(){
  console.info('screen dump db...');
  await appdb.iterate(function(value, key) {
      console.log([key, value]);
  });
}

function appinit(){
  console.info('Browser: %o', window.bowser.parse(window.navigator.userAgent));
  const browser = window.bowser.getParser(window.navigator.userAgent);
  console.warn('Browser: ', browser);
  const isValidBrowser = browser.satisfies({
    mobile: {
      safari: '>11',
      'android browser': '>17'
    },
    chrome: ">84",
    firefox: ">79",
    safari: ">12"
  });
  if(!isValidBrowser){
    window.location.assign(`unsupported.html`);
    return;
  }

  let val = localStorage.getItem('bmapi-stage');
  if(val){
    _apistage = val;
  }
  console.info(`Stage is ${_apistage}`);

  addDarkModeSelector();
  watchDarkMode();
}


appinit();
const bmtools = {
  setstage: setstage,
  store: store,
  appdb: _appdb,
  dbdump: _dbdump
}
window.bmtools = bmtools;

function isqueryroute(path){
  return _queryroutes.some(function(s){
    if(path.startsWith(s)){
      return true;
    }
  })
}

function isintegrationroute(path){
  return _integrationroutes.some(function(s){
    if(path.startsWith(s)){
      return true;
    }
  })
}

///////////////////

export const bmapi = ky;

export const DateTime = luxondt;

export const sleep = m => new Promise(r => setTimeout(r, m));

export const apistage = _apistage;

export const appdb = _appdb;

export function getlocale(){
  let lang = "en";
  let region = "US";
  try{
    let basename = navigator.language;
    if(!basename){
      basename = `${lang}-${region}`
    }
    let navlangs = navigator.languages;
    if(!navlangs){
      navlangs = [basename];
    }
    if(navlangs.length < 1){
      navlangs = [basename];
    }
    const locale = new Intl.Locale(navlangs[0]);
    console.info(`Locale is: ${locale.baseName}`, locale);
    return locale;

  } catch (inite) {
    console.error(inite);
    throw inite;
  }
}

export function currencycode(region){
  //from ISO 4217 currency codes
  const defaultregioncurrencymap = {
    'US': 'USD',
    'GB': 'GBP',
    'CA': 'CAD',
    'MX': 'MXN',
  }
  let code = null;
  //this part needs access to geolocation. We need to do this better.
  // const appcfg = store('appcfg');
  // let cfg = lodash.get(appcfg, `appconf.core/${this.geodoc.currgeo}`);
  // if(!cfg){
  //   cfg = lodash.get(appcfg, `appconf.core/default`)
  // }
  // if(cfg.currencycode){
  //   code = cfg.currencycode;
  // }
  if(!code){
    code = defaultregioncurrencymap[region];
  }
  if(!code){
    code = 'USD';
  }
  return code;
}

export function currencysymbol(region){
  //from ISO 4217 currency codes
  const defaultregioncurrencymap = {
    'US': 'USD',
    'GB': 'GBP',
    'CA': 'CAD',
    'MX': 'MXN',
  }
  let code = null;
  //this part needs access to geolocation. We need to do this better.
  // const appcfg = store('appcfg');
  // let cfg = lodash.get(appcfg, `appconf.core/${this.geodoc.currgeo}`);
  // if(!cfg){
  //   cfg = lodash.get(appcfg, `appconf.core/default`)
  // }
  // if(cfg.currencycode){
  //   code = cfg.currencycode;
  // }
  if(!code){
    code = defaultregioncurrencymap[region];
  }
  if(!code){
    code = 'USD';
  }
  const fmt= Intl.NumberFormat(undefined, {style: "currency", currency:code})
  const parts = fmt.formatToParts(1);
  const el = parts.find(p => p.type === 'currency');
  return el.value;
}

export async function store(key, value){
  try{
    if(arguments.length > 1){
      await _appdb.setItem(key, value);
    } else {
      const result = await _appdb.getItem(key);
      return result;
    }
  } catch (lfe) {
    console.error(lfe);
    throw lfe;
  }
}

export function el(id){
  return document.getElementById(id)
}

export function getUrlParameter(name) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
}

export async function logout(){
  try{
    const fetchopts = await getfetchopts();
    await ky.get('access/logout', fetchopts);
  } catch (e) {
    console.error(e);
  }
  await store("session", {});
  await store("tmpvars", {});
  await _appdb.removeItem("appcfg");
  window.location.assign(`complete_logout.html`)
}

export async function routenoauth(){
  const pathname = window.location.pathname
  const session = {};
  if(pathname &&
     pathname.trim() &&
     pathname.indexOf('/') === 0 &&
     pathname.indexOf('/complete_login.html') !== 0)
  {
    session.authdest = pathname + window.location.search;
  }
  session.loginid = nanoid(18);
  session.apistage = _apistage
  await store('session', session);
  window.location.assign(`https://api.bmj.dcit.com${_apistage}/access/weblogin?loginid=${session.loginid}`)
}

export function navigate(location){
  console.info('Navigate: ', location);
  if(location.startsWith('http') || location.startsWith('/')){
    window.location.assign(location);
  } else {
    if(window.location.href.startsWith('http://localhost')){
      window.location.assign(location);
    } else {
      window.location.assign(`${_apistage}/${location}`);
    }
  }
}

export function gethref(location){
  if(location.startsWith('http') || location.startsWith('/')){
    return location;
  } else {
    if(window.location.href.startsWith('http://localhost')){
      return location;
    } else {
      return `${_apistage}/${location}`;
    }
  }
}

export function setstage(stage) {
  console.info('Setting stage to: %s', stage);
  localStorage.setItem('bmapi-stage', stage);
}

export async function getfetchopts(opts){
  let stageroute = '';
  if(opts && opts.path){
    if(isqueryroute(opts.path)){
      stageroute = '-query'
    } else if(isintegrationroute(opts.path)){
      stageroute = '-integrations'
    }
  }

  const kyopts = {
    prefixUrl: `https://api.bmj.dcit.com${_apistage}${stageroute}`,
    timeout: 30000,
    hooks: {
      beforeRequest: [
        request => {
          console.info(`Request: ${request.method} ${request.url}`);
          if(kyopts.json){
            console.info(JSON.stringify(kyopts.json, null, 2));
          }
        }
      ]
    },
    retry: 0
  };

  if(!_token){
    const session = await store('session');
    if(session && session.token){
      _token = session.token
    }
  }

  if(opts){
    lodash.assign(kyopts, opts);
  }

  if(_token){
    if(!kyopts.headers){
      kyopts.headers = {};
    }
    kyopts.headers['Authorization'] = `Bearer ${_token}`;
  }

  delete kyopts.path;

  return kyopts;
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function nanoid(t){
  if(!t){
    t = 21;
  }
  let e = "";
  let r = crypto.getRandomValues(new Uint8Array(t));
  for(;t--;){
    let n = 63&r[t];
    e += n < 36 ? n.toString(36) : n<62 ? (n-26).toString(36).toUpperCase() : n < 63 ? "_" : "-"
  }
  return e;
}

export function rndstr(t = 16, opts = {}){
  //ascii codes to exclude
  const excludecodes = [
    58, 59, 60, 61, 62, 63, 64, 91, 92, 93, 94, 95, 96, 79, 83, 66, 73, 90
  ]
  const max = (opts.allcaps) ? 90 : 122;
  let e = "";
  let r = crypto.getRandomValues(new Uint8Array(32)); //this should give values from 0 - 255
  let idx = 0;
  while(e.length < t){
    let n = r[idx];
    idx++;
    if(idx >= r.length){
      r = crypto.getRandomValues(new Uint8Array(32));
      idx = 0;
    }
    if(n < 48 || n > max || excludecodes.includes(n)){
      continue;
    } else {
      e += String.fromCharCode(n);
    }
  }
  return e;
}

export function rndstrnumeric(t = 10){
  let e = "";
  let r = crypto.getRandomValues(new Uint8Array(32)); //this should give values from 0 - 255
  let idx = 0;
  while(e.length < t){
    let n = r[idx];
    idx++;
    if(idx >= r.length){
      r = crypto.getRandomValues(new Uint8Array(32));
      idx = 0;
    }
    if(n < 48 || n > 57){
      continue;
    } else {
      e += String.fromCharCode(n);
    }
  }
  return e;
}


export async function getDeviceId(){
  let device = await store('device');
  if(!device){
    device = {
      device_id: uuidv4()
    };
  }
  if(!device.device_id){
    device.device_id = uuidv4();
    await store('device', device);
  }
  return device.device_id;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
