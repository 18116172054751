/* global Vue */
'use strict';
import * as utils from './utils.mjs';
import * as messages from '../messages/home.json';
const vc = require('./vuecommon.js');
let locale = null;

const data = {
  uis: {},
  vs: {
    globalrsc: []
  }
};

new Vue({
  el: "#app",

  mixins: [vc],

  data: function() {
    return data
  },

  created: async function created() {
    try{
      locale = utils.getlocale();
      let lm = messages[`${locale.language}-${locale.region}`];
      if(!lm){
        lm = messages['en-US'];
      }
      lm = this.mergeCommonMessages(locale, lm);
      this.uis.messages = lm;
    } catch (e) {
      console.error('Error setting up locale: ', e);
    }

    const url = new URL(window.location.href);
    const sp = url.searchParams;
    if(sp.has('setstage')){
      console.info('Setting stage from query!!!')
      const stage = url.searchParams.get('setstage');
      utils.setstage(`/${stage}`);
      await utils.sleep(5000);
    }

    try{
      let fetchopts = await utils.getfetchopts({
        path: `authnoop`
      });
      const result = await utils.bmapi.get(`authnoop`, fetchopts).json();
      console.info('Result: ', result);
    } catch (e) {
      this.handleError(e, this);
      return;
    }

    this.fetchdata();
  },

  methods:{
    // fetchdata: async function fetchdata() {
    //   try{
    //     const result = await utils.bmapi.get('partial_homenews.html').text(); //using a plain ky instance here to fetch from cloudfront
    //     this.vs.news = result;
    //     this.uis.loaded = true;
    //   } catch (e){
    //     this.handleError(e, this);
    //   }
    // },

    fetchdata: async function fetchdata() {
      try{
        const fetchopts = await utils.getfetchopts({
          path: `home`
        });
        const result = await utils.bmapi.get(`home`, fetchopts).json();
        const rows = [];
        let row = []
        result.globalrsc.forEach((rsc) => {
          if(rsc.size == 100){
            rows.push([rsc]);
          } else if(rsc.size == 50){
            if(row.length < 1){
              row.push(rsc);
            } else {
              row.push(rsc);
              rows.push(row);
              row = [];
            }
          } else {
            if(row.length < 2){
              row.push(rsc);
            } else {
              row.push(rsc);
              rows.push(row);
              row = [];
            }
          }
        });
        this.vs.globalrsc = rows;
        this.uis.loaded = true;
      } catch (e){
        this.handleError(e, this);
      }
    },

    routeToDownload: function routeToDownload(){
      window.open("https://www.dcit.com/download", "_blank");
    }
  }
})
