'use strict';
const lodash = window._;

export function getString(messages, key, defaultStr, beautify){
  let result = lodash.get(messages, key);
  if(!result && !lodash.isNil(defaultStr)){
    result = defaultStr;
  } else if(!result){
    result = key;
  }
  if(beautify){
    result = result.replaceAll('_', ' ');
    return capitalizeWords(result);
  }
  return result;
}


function capitalizeWords(str){
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0, len=splitStr.length|0; i < len; i=i+1|0) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
  }
  return splitStr.join(' ');
}
